import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ThrowStmt } from '@angular/compiler';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/rain-check-store-login', title: 'Store Login', icon: 'pe-7s-id', class: '' },
  // { path: '/rain-check-list', title: 'All', icon: 'pe-7s-home', class: '' },

  // { path: '/rain-check-store-list', title: 'Store View', icon: 'pe-7s-map-marker', class: '' },
  // { path: '/rain-check-store-region', title: 'Region View', icon: 'pe-7s-map', class: '' },


  // { path: '/rain-check-list', title: 'RainCheck Log', icon: 'fa fa-list', class: '' }

];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  user = false;

  constructor(private router: Router) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };

  showLogin() {
    Swal.mixin({
      input: 'text',
      confirmButtonText: 'Next &rarr;',
      showCancelButton: true,
      progressSteps: ['1', '2']
    }).queue([
      {
        title: 'Enter Username',
      },
      'Confirm Username',
    ]).then((result) => {
      console.log(result.value);
      if (result.value[0] == result.value[1]) {
        Swal({
          title: 'All done!',
          type: 'success',
          html:
            'You are Successfully Logged In as: <pre><code>' +
            'Super Admin' +
            '</code></pre>',
          confirmButtonText: 'Done!'
        });
        this.user = true;
        this.router.navigate(['/rain-check-list'])
      } else {
        Swal({
          title: 'Error!',
          type: 'error',
          text: 'YOU ARE NOT AUTHORIZED!',
          confirmButtonText: 'Done!'
        })
        this.user = false;
        this.router.navigate(['/rain-check-store-login']);
      }
    })
  }
  logout() {
    this.user = false;
    this.router.navigate(['/rain-check-store-login']);
  }
}
