// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  firebase:{
    apiKey: "AIzaSyB3ir8yfDKGLKL1AxSc_FcEb4w18ZBI7oQ",
    authDomain: "raincheck-224113.firebaseapp.com",
    databaseURL: "https://raincheck-224113.firebaseio.com",
    projectId: "raincheck-224113",
    storageBucket: "raincheck-224113.appspot.com",
    messagingSenderId: "568103717935"
  }

};
